.wraper {
  background: var(--chat-bg);
  flex: 1;
  padding: 2% 5%;
  color: var(--primary-color);
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .settingHead {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .list {
    display: flex;
    font-size: 14px;
    color: #73706e;
    justify-content: center;

    li {
      width: 203px;
      height: 40px;
      border-bottom: 3px solid transparent;
      text-align: center;
      cursor: pointer;

      img {
        vertical-align: middle;
        margin-right: 8px;
        opacity: 0;
      }

      &.active {
        border-bottom-color: #5273e8;
        color: #5273e8;
        font-weight: 700;

        img {
          opacity: 1;
        }
      }
    }
  }

  .pay {
    max-width: 550px;
    width: 100%;
    margin-top: 5%;
    flex: 1;
    overflow: auto;
    & > div {
      display: flex;
      align-items: center;
      margin-bottom: 8%;

      & > p {
        display: flex;
        align-items: center;
        font-weight: 700;
      }
    }

    .method,
    .order,
    .price {
      .title {
        padding-right: 2%;
        word-break: keep-all;
      }
    }
    .orderTypes {
      //会员类型样式，共4类会员，2*2对齐
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      align-items: start;
      justify-content: start;
      flex: 1;
      font-size: 16px !important;
    }

    .type {
      margin-right: 8%;
      display: flex;
      align-items: center;

      img {
        padding-right: 2%;
      }
    }
    .payType {
      display: flex;
      align-items: center;
      .ali {
        height: 34px;
        width: 34px;
        background: url("../public/setting/ali.png") no-repeat 100%;
        background-size: contain;
        margin-right: 16px;
      }
      .wx {
        height: 34px;
        width: 34px;
        background: url("../public/setting/wechat.png") no-repeat 100%;
        background-size: contain;
        margin-right: 16px;
      }
    }

    .tips {
      font-size: 16px;
      color: #8e8e8e;
      text-align: center;
    }

    .bindCard {
      display: block;
      width: 419px;
      height: 52px;
      border-radius: 6px;
      background: #005bd8;
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      cursor: pointer;
    }
  }
}

.send_code {
  position: absolute;
  cursor: pointer;
  color: #005bd8;
  top: 48px;
  right: 18px;
}

.form {
  max-width: 700px;
  width: 100%;
  flex: 1;
  padding: 0 1%;
  overflow: auto;
  .info_part {
    position: relative;
    margin-top: 5%;

    .info_label {
      font-size: 12px;
      color: var(--primary-color);
      font-weight: bold;
      padding-bottom: 10px;

      sup {
        color: #dd0043;
      }
    }

    p {
      position: absolute;
      right: 35px;
      top: 0;
      font-size: 10px;
      line-height: 40px;
      color: #ff0000;
    }

    .icons {
      position: absolute;
      top: 45px;
      right: 20px;
      cursor: pointer;
    }

    .select {
      width: 24px;
      height: 14px;
      position: absolute;
      top: 50px;
      right: 32px;
      cursor: pointer;
      background: url("../public/setting/select.png");

      &.icons_show {
        transform: rotate(180deg);
      }
    }

    .selectDown {
      width: 100%;
      position: absolute;
      background-color: var(--setting-input-bg);
      border-radius: 6px;
      top: 84px;
      font-size: 14px;

      li {
        padding: 20px;
        cursor: pointer;
      }
    }

    .active {
      border: 1px solid #ff0000 !important;
    }

    .img_part {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .third_part {
    display: flex;
    margin-top: 32px;
    align-items: center;
    margin-bottom: 14px;
    span {
      padding-right: 27px;
    }
    img {
      padding-right: 46px;
    }
  }

  h2 {
    font-size: 12px;
    color: #fff;
    margin-bottom: 52px;
    font-weight: 500;
  }

  input {
    width: 100%;
    height: 53px;
    position: relative;
    box-sizing: border-box;
    font-size: 14px;
    background: var(--setting-input-bg);
    border: 1px solid transparent;
    border-radius: 10px;
    padding-left: 18px;

    color: var(--primary-color) !important;
    outline: 0;
    &.info_input {
      background: var(--setting-disabled-bg);
      color: #222222 !important;
    }
  }

  input[placeholder],
  [placeholder],
  *[placeholder] {
    color: #8e8e8e;
  }

  input::-webkit-input-placeholder {
    color: #8e8e8e;
  }

  .Login_to {
    width: 100%;
    height: 52px;
    background: #005bd8;
    color: #fff;
    border-radius: 2px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    line-height: 52px;
    margin-bottom: 36px;
    margin-top: 127px;
    border-radius: 6px;
    cursor: pointer;
  }

  .info_other {
    display: flex;
    justify-content: space-between;
    font-size: 10px;
    margin-top: 18px;

    .remember {
      color: #f2f4f8;
      display: flex;
      align-items: center;

      span {
        background: url("../public/login/unSelected.png") no-repeat;
        width: 21px;
        height: 21px;
        background-size: 21px 21px;
        margin-right: 16px;

        &.active {
          background: url("../public/login/selected.png") no-repeat;
        }
      }
    }

    .register {
      color: #005bd8;
      cursor: pointer;
    }
  }

  .wechat {
    margin-top: 32px;
    display: flex;
    align-items: center;
    color: #fff;

    img {
      width: 34px;
      height: 27px;
      margin-left: 10px;
    }
  }
}
.tableSpin {
  max-height: none !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.log {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 0 1%;
}
.table {
  margin-top: 5%;
  width: 100%;
  flex: 1;
  overflow: auto;
  table {
    color: #fff;
    width: 100%;

    th {
      width: 20%;
      background: #005bd8;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
    }
    td {
      border-bottom: 1px solid var(--setting-table-border);
      border-right: none;
      height: 62px;
      font-size: 14px;
      color: var(--setting-table-text);
    }
  }
}
.page_tit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 54px;
  margin-top: 12px;
}

.originalPrice {
  //划线价格样式
  text-decoration: line-through;
  margin-right: 10px;
}
.QRCodeBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.editBtns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  .btn {
    margin: 0 10px;
    height: 52px;
  }
  .saveBtn {
    width: 419px;
    height: 52px;
  }
}
