.faFile {
  background: url("./../../public/index/leaf.png") no-repeat center center;
  background-size: cover;
  width: 20px;
  height: 18px;
}
.FaFolder {
  background: url("./../../public/index/file_close.png") no-repeat center center;
  background-size: cover;
  width: 20px;
  height: 18px;
}
.FaFolderOpen {
  background: url("./../../public/index/file_open.png") no-repeat center center;
  background-size: cover;
  width: 20px;
  height: 18px;
}
.FaChevronRight {
  background: url("./../../public/index/chevronRight.png") no-repeat center
    center;
  background-size: cover;
  width: 7px;
  height: 10px;
}
.FaChevronDown {
  background: url("./../../public/index/chevronDown.png") no-repeat center
    center;
  background-size: cover;
  width: 10px;
  height: 6px;
}
.loadingIcon{
  width: 35px;
  height: 35px;
  background: url("./../../public/loading/loading2.gif") no-repeat;
  background-size: 100%;
}