.icon {
  width: 100%;
  height: 60vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.uml {
  background-image: url('../../public/guideGif/UML.gif');
}
.light_theme {
  background-image: url('../../public/guideGif/light_theme.gif');
}
.copy {
  background-image: url('../../public/guideGif/copy.gif');
}
.mb {
  background-image: url('../../public/guideGif/mb.gif');
}
.carousel {
  :global {
    .slick-prev,.slick-next{
      color: var(--primary-color);
    }
  }
}