.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: var(--primary-color);
  padding: 30px 0;
}
.login_body {
  background-size: 100% 100%;
  width: 100%;
  .login_content {
    .login_form {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .weChatBox {
        position: relative;
        border-radius: 30px;
        height: 60%;
        aspect-ratio: 1;
        min-height: 400px;
        min-width: 400px;
        max-width: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10%;
        .title {
          font-size: 32px;
          line-height: 62px;
          color: var(--primary-color);
          font-weight: bold;
          padding-left: 0;
        }
      }
      .form {
        width: 420px;
        height: 310px;
        margin: 0 auto 0;

        .info_part {
          position: relative;
          margin-top: 28px;

          .info_label {
            font-size: 1em;
            color: var(--primary-color);
            font-weight: bold;
            padding-bottom: 10px;

            sup {
              color: #dd0043;
            }
          }

          p {
            position: absolute;
            right: 35px;
            top: 0;
            font-size: 10px;
            line-height: 40px;
            color: #ff0000;
          }

          .icons {
            width: 24px;
            height: 17px;
            position: absolute;
            top: 50px;
            right: 32px;
            cursor: pointer;
            background: url("../public/login/open.png");

            &.icons_show {
              background: url("../public/login/close.png");
            }
          }

          .active {
            border: 1px solid #ff0000 !important;
          }

          .img_part {
            position: absolute;
            right: 0;
            top: 0;
          }
        }

        h2 {
          font-size: 12px;
          color: var(--primary-color);
          margin-bottom: 52px;
          font-weight: 500;
        }

        input {
          width: 100%;
          height: 53px;
          position: relative;
          box-sizing: border-box;
          font-size: 14px;
          background: var(--login-form-bg);
          border: 1px solid var(--login-form-bg);
          border-radius: 10px;
          padding-left: 18px;

          color: var(--primary-color) !important;
          outline: 0;
        }

        input[placeholder],
        [placeholder],
        *[placeholder] {
          color: var(--login-form-placeholder);
        }

        input::-webkit-input-placeholder {
          color: var(--login-form-placeholder);
        }

        .Login_to {
          width: 100%;
          height: 52px;
          background: #005bd8;
          color: #fff;
          border-radius: 2px;
          font-size: 14px;
          font-weight: bold;
          text-align: center;
          line-height: 52px;
          margin-bottom: 36px;
          margin-top: 67px;
          border-radius: 6px;
          cursor: pointer;
        }

        .info_other {
          display: flex;
          justify-content: space-between;
          font-size: 10px;
          margin-top: 18px;

          .remember {
            color: var(--login-remember-text);
            display: flex;
            align-items: center;

            span {
              background: url("../public/login/unSelected.png") no-repeat;
              width: 21px;
              height: 21px;
              background-size: 21px 21px;
              margin-right: 16px;

              &.active {
                background: url("../public/login/selected.png") no-repeat;
              }
            }
          }

          .register {
            color: #005bd8;
            cursor: pointer;
          }
        }

        .wechat {
          margin-top: 10px;
          display: flex;
          align-items: center;
          color: var(--login-remember-text);

          img {
            width: 34px;
            height: 27px;
            margin-left: 10px;
          }
        }
      }

      .read {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #b6b6b6;
        width: 100%;
        .checkbox {
          background: url("../public/login/unSelected.png") no-repeat;
          width: 1vw;
          height: 1vw;
          background-size: 1vw 1vw;
          margin-right: 2%;

          &.active {
            background: url("../public/login/selected.png") no-repeat;
            background-size: 1vw 1vw;
          }
        }
      }
    }
  }

  .login_foot {
    width: 100%;
    color: #fff;
    font-size: 12px;
    padding: 24px 0px;
    padding-top: 42px;
    text-align: center;
  }
}

.send_code {
  position: absolute;
  cursor: pointer;
  color: #005bd8;
  top: 46px;
  right: 18px;
  font-size: 16px;
}

.userLoginBox {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  h2 {
    font-weight: 500;
    margin-bottom: 5%;
    font-size: 20px;
  }
  .desc {
    margin-bottom: 10px;
  }
  .QRBox {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 15vw;
    aspect-ratio: 1;
    background-color: #fff;
  }
  .QRMask {
    position: relative;
    display: inline-block; /* 让伪元素的大小与图片大小一致 */
    width: 15vw;
    aspect-ratio: 1;
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../public/login/QRmask.png") no-repeat;
      background-size: cover;
      pointer-events: none; /* 允许点击穿透蒙层 */
      z-index: 99;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #8e8e8e;
      opacity: 0.5;
      background-size: cover;
      pointer-events: none; /* 允许点击穿透蒙层 */
    }
  }
  .wechat_qr_img {
    width: 100%;
    height: 100%;
  }
  .wechat_qr_text {
    font-size: 1.2vh;
    text-align: center;
    margin-bottom: 10px;
  }
  .read {
    padding-top: 5%;
    font-size: 1.2vh;
    position: relative;
    z-index: 2;
  }
  .conjunction {
    white-space: nowrap;
  }
  .link {
    color: #005bd8;
    cursor: pointer;
    margin: 0 4px; // 增加左右边距
    white-space: nowrap; // 防止链接文本换行
  }
}
.tips {
  color: #8e8e8e;
  width: 100%;
  text-align: center;
  padding: 8px;
  font-size: 0.8em;
  a {
    color: #007bff;
  }
}
.phoneCodeBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  .phoneCodeTitle {
    font-size: 34px;
    color: var(--primary-color);
    font-weight: bold;
  }
  .phone_form {
    width: 100%;
    margin: 0 auto 0;

    .info_part {
      position: relative;
      margin-top: 28px;

      .info_label {
        font-size: 1em;
        color: var(--primary-color);
        font-weight: bold;
        padding-bottom: 10px;
        text-align: left;
      }

      input {
        width: 100%;
        height: 53px;
        position: relative;
        box-sizing: border-box;
        font-size: 12px;
        background: var(--login-form-bg);
        border: 1px solid var(--login-form-bg);
        border-radius: 10px;
        padding-left: 18px;

        color: var(--primary-color) !important;
        outline: 0;
      }
      sup {
        color: #dd0043;
      }
      input[placeholder],
      [placeholder],
      *[placeholder] {
        color: var(--login-form-placeholder);
      }

      input::-webkit-input-placeholder {
        color: var(--login-form-placeholder);
      }
    }
    .Login_to {
      width: 100%;
      height: 52px;
      background: #005bd8;
      color: #fff;
      border-radius: 2px;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      line-height: 52px;
      margin-top: 5%;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      z-index: 999;
    }
    .phone_read {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #b6b6b6;

      .checkbox {
        background: url("../public/login/unSelected.png") no-repeat;
        width: 1vw;
        height: 1vw;
        background-size: cover;
        margin-right: 16px;

        &.active {
          background: url("../public/login/selected.png") no-repeat;
        }
      }
    }
  }
}
