.mask{
    width: 100%;
    height: calc(100vh);
    position: fixed;
    top: 0;
    left: 0;
    background: #00000038;
    z-index: 10;
  }
  .mask_lod{
    width: 80px;
    height: 80px;
    position: absolute;
    left: 60%;
    top: 50%;
    margin-top: -180px;
    margin-left: -102px;
    background: url("../public/loading/loading2.gif") no-repeat;
    background-size: 70%;
    z-index: 20;
  }
  .error{
    width: 200px;
    height: 30px;
    position: fixed;
    top: 10px;
    left: 50%;
    margin-left: -100px;
    text-align: center;
    line-height: 30px;
    font-size: 10px;
    transition:0.7s;
    -webkit-transition-timing-function:ease-in-out;
    opacity : 0;
  }
  .error_true{
    width: 200px;
    height: 30px;
    background-color: #f0f9eb;
    border-color: #e1f3d8;
    position: fixed;
    top: 40px;
    left: 50%;
    margin-left: -100px;
    color: #67c23a;
    text-align: center;
    line-height: 30px;
    font-size: 10px;
    transition:0.7s;
    -webkit-transition-timing-function:ease-in-out;
    opacity : 1;
  }
  .error_false{
    background-color: #fef0f0;
    border-color: #fde2e2;
    width: 200px;
    height: 30px;
    position: fixed;
    top: 40px;
    left: 50%;
    margin-left: -100px;
    color: #f56c6c;
    text-align: center;
    line-height: 30px;
    font-size: 10px;
    transition:0.7s;
    -webkit-transition-timing-function:ease-in-out;
    opacity : 1;
  }
  
  