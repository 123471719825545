.fileUpload {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .upload {
    width: 52px;
    height: 52px;
    cursor: pointer;
  }
  .upLoadTips {
    font-size: 15px;
    color: #8E8E8E;
  }
  .icon_light {
    background: url(../../public/chat/upload_light.png) no-repeat 100% 100%;
    background-size: cover;
  }
  .icon {
    background: url(../../public/chat/upload.png) no-repeat 100% 100%;
    background-size: cover;
  }
}

.file {
  display: none;
}
