.chatpdf {
  display: flex;
  height: 100vh;
  flex-direction: row;
  // background: url('../public/chat/bg_dark.png') no-repeat;
  background-color: var(--chat-bg);
  background-size: cover;
  padding: 0 0 0 0;
  color: var(--slider-text);
  position: relative;
  .dragover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--dragover-bg);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    pointer-events: none;
    z-index: 99;
    .dragover_text {
      font-size: 24px;
      font-weight: 600;
      margin-top: 10px;
    }
    .dragover_accept {
      font-size: 16px;
      font-weight: 400;
      margin-top: 10px;
    }
  }
  .logo {
    background: url("../public/logo.png") no-repeat 47px 10px;
    background-size: 64px 64px;
    font-size: 60px;
    font-weight: 700;
    margin-top: 9px;
    padding-left: 124px;
    color: var(--slider-logo);
  }

  .time {
    font-size: 14px;
    line-height: 20px;
    padding: 26px 0 18px 45px;
  }
  .fileBox {
    overflow: auto;
    height: calc(100vh - 350px);
  }
  .fileList {
    height: 100%;
  }
  .repository {
    .fullName {
      font-weight: 600;
      font-size: 16px;
      margin: 10px 40px 10px 40px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .branchName {
      font-weight: 600;
      font-size: 14px;
      margin: 10px 40px 10px 40px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .fileTree {
      padding: 0px 15px 0px 0px;
      overflow: auto;
      max-height: calc(100vh - 430px);
    }
  }
}

.chatpdf .pannel {
  width: 18%;
  max-width: 383px;
  min-width: 300px;
  height: 100%;
  background: var(--slider-bg) 285px bottom;
  // border-radius: 18px 0 0 18px;
  transition: width 0.3s ease;
  display: flex;
  flex-direction: column;
  &.collapsed {
    width: 100px; // 折叠时的宽度
    min-width: 0 !important;
    overflow: hidden;
    background-color: transparent;

    .logo,
    .titleBox,
    .fileList,
    .line,
    .userInfo {
      display: none;
    }
  }
  .sliderline {
    height: 1px;
    width: 90%;
    background-color: var(--slider-line);
    margin: 0 auto 20px;
  }
  .line {
    height: 1px;
    width: 90%;
    background-color: var(--slider-line);
    margin: 20px auto 0;
  }
  .sidebarControls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    padding: 10px 25px;
  }

  .toggleSidebar {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    .icon {
      &:hover {
        animation: shake 0.5s ease-in-out 1;
      }
    }
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 70px;
    .switchBtn {
      cursor: pointer;
      width: 100%;
      height: 100%;
      margin-right: 15px;
      .icon {
        margin-left: 0;
        &:hover {
          animation: shake 0.5s ease-in-out 1;
        }
      }
    }
    .editIcon {
      cursor: pointer;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      &:hover {
        background-color: rgba(0, 0, 0, 0.05); // 鼠标悬停时的背景色
        border-radius: 50%; // 圆形悬停效果
      }
      svg {
        margin-left: 0;
        &:hover {
          animation: shake 0.5s ease-in-out 1;
        }
      }
    }
  }

  .logoContainer {
    display: flex;
    align-items: baseline;
    justify-content: center;
    user-select: none; /* 禁止文本选择 */
    -webkit-user-select: none; /* 适用于 Safari */
    -moz-user-select: none; /* 适用于 Firefox */
    -ms-user-select: none; /* 适用于 IE 10+ */
    .text_logo {
      width: 124px; // 调整为你需要的宽度
      height: 40px; // 调整为你需要的高度
      background: url("../public/title.png") no-repeat center center;
      background-size: contain;
    }
    .textBlob {
      margin-right: 5px;
      font-weight: bold;
      font-size: 18px;
    }
    .text {
      font-size: 14px;
    }
  }
  .contentBox {
    flex: 1;
  }
  .notLoginBox {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .historyIcon {
      background: url("/chat/historyMsg.png") no-repeat 100%;
      background-size: cover;
      width: 44px;
      height: 38px;
      margin-bottom: 16px;
    }
    .historyText {
      border-radius: 15px;
      border: 1px solid rgba(151, 151, 151, 0.3);
      padding: 2px 10px;
    }
  }
  .smallImageContainer {
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }

  .smallImage {
    width: 100px; // 调整这个值来改变图片大小
    height: auto;
  }
  .customerText {
    font-size: 16px;
    text-align: center;
    margin: 0 0 10px;
  }
  .userInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    border-radius: 8px;
    margin: 5px;
    bottom: 20px;
    font-size: 14px;

    .infoContent {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      margin: 0 4px;
      white-space: nowrap; // 防止内容换行
    }
    .money {
      margin-right: 10px;
    }
    .upgrade {
      margin-left: 0px;
      cursor: pointer;
      text-decoration: underline;
    }
    .slide {
      margin-left: 30px;
      margin-right: 30px;
    }
    .btnBox {
      display: flex;
      justify-content: center;

      .setting,
      .exitBtn {
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px;
        transition: background-color 0.3s;

        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }

        &.light {
          color: #000;
        }
      }
      .setting {
        width: 25px;
        height: 25px;
        &:hover {
          animation: shake 0.5s ease-in-out 1;
        }
      }
      .settingDark {
        background: url("../public/chat/setting.png") no-repeat center / contain;
      }
      .settingLight {
        background: url("../public/chat/setting-light.png") no-repeat center /
          contain;
      }
      .exitBtn {
        width: 25px;
        height: 25px;
        &:hover {
          animation: shake 0.5s ease-in-out 1;
        }
      }
      .exitLight {
        background: url("../public/chat/exit-light.png") no-repeat center /
          contain;
      }
      .exitDark {
        background: url("../public/chat/exit.png") no-repeat center / contain;
      }
    }
  }
}
.guideLink {
  margin-left: 10px;
  font-size: 14px;
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
// 右边
.chatpdfBox {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: var(--chat-bg);
  background-size: cover;
  background-attachment: fixed;
  padding: 0 2% 0 2%;
  height: 100%;
  transition: margin-left 0.3s ease;
  overflow: hidden;
  .chatHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1% 0;
  }

  .chatContent {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .chatBottom {
    min-height: 128px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.chatpdfLine {
  flex: 1;
  width: 100%;
  margin: 0 auto 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column; /* 让最新内容在下方 */
  padding-bottom: 20px; /* 给输入框预留空间 */
  position: relative;
  padding-right: 5px;
}

.chatpdfIcon {
  width: 60px;
  height: 60px;
  background: url(../public/logo.png);
  background-size: 60px 60px;
  margin-right: 10px;
}

.chatpdfRow {
  margin-bottom: 16px;
  display: flex;
  .chatpdfAsk {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
  }
  .fileBox {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 70px;
    margin-right: 70px;
    margin-bottom: 8px;
  }
  .file {
    display: flex;
    align-items: center;
    border-radius: 20px;
    background: var(--chat-ask-bg);
    padding: 16px;
    max-width: 400px;
    .fileBg {
      background: url("./../public/chat/file.png") 100% 100% no-repeat;
      background-size: cover;
      width: 40px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-items: center;
    }
    .fileName {
      font-size: 15px;
      margin-left: 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.askBox {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  .chatpdfIcon {
    margin-left: 10px;
    margin-right: 0;
    background: var(--user-icon-light) no-repeat;
    background-size: 60px 60px;
  }
}
.gptsDiv {
  height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: transparent;
  border-radius: 10px;
  position: relative;

  // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.productLogo {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}
.productTitle {
  font-size: 24px;
  font-weight: bold;
  color: var(--chat-left-text);
  margin-bottom: 10px;
}
.productDescription {
  width: 90%;
  text-align: center;
  color: var(--chat-left-text);
  font-size: 16px;
  line-height: 1.5;
}
.chatAskPdfContent {
  display: inline-block;
  border-radius: 18px;
  padding: 18px;
  background: var(--chat-ask-bg);
  color: var(--chat-left-text);
  font-size: 16px;
  line-height: 28px;
  text-align: left;
  max-width: calc(60% - 90px);
  overflow: hidden;
  word-break: break-all;
}
.chatpdfContent {
  display: inline-block;
  border-radius: 18px;
  padding: 18px;
  background: var(--chat-bg);
  color: var(--chat-left-text);
  font-size: 16px;
  line-height: 28px;
  width: calc(100% - 90px);
  word-break: break-all;
  overflow: hidden;
  position: relative;
  .toolBtns {
    margin-top: 8px;
    .copy {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      cursor: pointer;
      border-radius: 5px;
      &:hover {
        background-color: var(--copy-icon-hover_bg);
      }
      .copyIcon {
        width: 20px;
        height: 20px;
        background: var(--copy-icon) 100% 100% no-repeat;
        background-size: cover;
      }
    }
  }
}
.chatpdfTipsContainer {
  display: flex;
  justify-content: center;
}
.loadingContainer {
  display: flex;
  align-items: center;
}

.thinkingText {
  margin-left: 10px;
  animation: thinking 1.5s infinite;
}

@keyframes thinking {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.chatpdfTips {
  flex: 0 1 auto; // 允许 flex item 收缩但不增长
  cursor: pointer;
  background: var(--chat-bg);
  border: 1px solid #979797;
  border-radius: 30px;
  font-size: 12px;
  color: var(--chat-tips-text);
  line-height: 36px;
  padding: 0 59px;
  width: 40%;
  max-width: 400px; // 设置最大宽度
  text-align: center;
  overflow-y: auto;
  max-height: 200px;

  &:first-child {
    margin-right: 10px; // 为第一个 tip 添加右边距
  }

  &:last-child {
    margin-left: 10px; // 为第二个 tip 添加左边距
  }
}
.chatpdfAsk .chatpdfContent {
  background: var(--chat-left-bg);
  color: var(--chat-left-text);
  border-radius: 15px;
}

.chatpdfArea {
  flex: 1;
  margin: 0 auto;
  width: 90%;
  // margin-left: 5%;
  position: relative;
  // max-height: calc(100vh - 350px); /* 限制最大高度，防止推高页面 */
  min-width: 20px;
  overflow: hidden; /* 隐藏溢出部分 */
  z-index: 10;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 10px;
  .send,
  .svg {
    width: 38px; /* 设置固定宽度 */
    height: 38px; /* 设置固定高度 */
    cursor: pointer;
    z-index: 2; /* 确保不会被其他元素覆盖 */
  }
  .sendDisabled {
    width: 38px; /* 设置固定宽度 */
    height: 38px; /* 设置固定高度 */
    z-index: 2; /* 确保不会被其他元素覆盖 */
  }
  .areaBox {
    flex: 1;
    margin-left: 1%;
    display: flex;
    flex-direction: column;
    .chatTip {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      color: var(--chat-tips-color);
      .chatTipText {
        display: flex;
        align-items: center;
        background: var(--chat-tips-bg);
        border-radius: 40px;
        padding: 10px 20px;
        &::before {
          content: "";
          background-image: var(--chat-tips-icon);
          background-size: cover;
          width: 16px;
          height: 16px;
          margin-right: 10px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
    .input {
      width: 100%;
      background-color: var(--chat-send-bg);
      border-radius: 30px;
      padding: 10px 10px 10px 30px;
      overflow-x: hidden;
      overflow-y: auto;
      .chatTextAreaBox {
        flex: 1;
        position: relative;
        background: var(--chat-send-bg);
        display: flex;
        align-items: flex-end; /* 让内容从顶部开始 */
        align-items: flex-end;
        justify-content: space-between;
        position: relative;
        color: #fff;
        min-height: 20px;
        .textareaBox {
          display: flex;
          align-items: center;
          width: 100%;
          min-height: 38px;
        }
      }
      .textarea {
        background-color: var(--chat-send-bg);
        width: 90%;
        outline: none;
        border: none;
        resize: none;
        white-space: pre-wrap;
        min-height: 20px; /* 保持与 input 容器一致的最小高度 */
        max-height: 30vh;
        word-wrap: break-word;
        line-height: 1.5;
        box-sizing: border-box; /* 确保 padding 不会影响宽度计算 */
        transition: height 0.3s ease; /* 平滑的高度变化 */
        display: flex; /* 使用flex布局 */
        align-items: center; /* 垂直居中 */
        box-sizing: border-box; /* 包括内边距和边框在内的大小计算 */
        overflow: auto; /* 允许滚动 */
        -ms-overflow-style: none; /* IE 和 Edge 浏览器隐藏滚动条 */
        scrollbar-width: none; /* Firefox 隐藏滚动条 */
        &::-webkit-scrollbar {
          display: none; /* Chrome、Safari 和 Opera 隐藏滚动条 */
        }
        &[placeholder],
        [placeholder],
        *[placeholder] {
          color: #8e8e8e;
          font-size: 16px;
          line-height: 1.5;
        }
        &::placeholder {
          display: flex; /* 确保placeholder也使用flex布局 */
          align-items: center; /* 垂直居中 */
        }
        &::-webkit-input-placeholder {
          color: #8e8e8e;
          font-size: 16px;
          line-height: 1.5;
        }
      }
      .file {
        display: flex;
        align-items: flex-end;
        .upLoadFileBox {
          display: flex;
          align-items: flex-end;
          position: relative;
          border-radius: 10px;
          padding: 3px 3px;
          .fileBg {
            background: url("./../public/chat/file.png") 100% 100% no-repeat;
            background-size: cover;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-items: center;
          }
          .fileName {
            font-size: 15px;
            margin-left: 8px;
          }
          .closeBtn {
            position: absolute;
            right: 0;
            top: 0;
            cursor: pointer;
            display: none;
          }
          &:hover {
            .closeBtn {
              display: block;
            }
          }
        }
      }
    }
  }
}
.custom_tooltip {
  --bs-tooltip-bg: #fff;
  --bs-tooltip-color: #282828;
}

.tips {
  position: relative;
  font-size: 0.8em;
  color: #8e8e8e;
  text-align: center;
  padding-bottom: 12px;
  a {
    color: #007bff;
  }
}
.file_name {
  position: absolute;
  bottom: 0;
  margin-right: 20px;
  font-size: 12px;
  z-index: 20;
  color: #777272;
}
.fileItem {
  margin: 0 24px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  .fileTitle {
    flex: 1;
    border-radius: 12px;
    padding: 0 16px;
    font-size: 14px;
    line-height: 48px;
    cursor: pointer;
    height: 48px;
    &.active {
      background: var(--chat-list-active-bg);
      line-height: 48px;
      height: 48px;
      color: #fff;
    }
  }

  .delete {
    background: url("../public/chat/delete.png") no-repeat;
    background-size: 16px 16px;
    width: 20px;
    height: 20px;
    margin-left: 17px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    &:hover {
      animation: shake 0.5s ease-in-out 1;
    }
  }
}

@media (max-height: 900px) {
  .gptsDiv {
    height: 300px;
    font-size: 16px;
  }
  .productLogo {
    width: 50px;
    height: 50px;
  }
  .productTitle {
    font-size: 20px;
  }
  .productDescription {
    font-size: 14px;
  }
  .chatpdfTips {
    width: 100%;
  }
}
@media (max-height: 700px) {
  .gptsDiv {
    height: 200px;
    font-size: 12px;
  }
  .productLogo {
    width: 30px;
    height: 30px;
  }
  .productTitle {
    font-size: 16px;
  }
  .productDescription {
    font-size: 12px;
  }
  .chatpdfTips {
    width: 100%;
  }
}
@media (max-width: 1200px) {
  .chatpdfTips {
    width: 100%;
    padding: 0 20px;
  }
}
@media (max-width: 900px) {
  .chatpdfTips {
    width: 100%;
    padding: 0 10px;
    overflow: hidden;
  }
}
@media (max-width: 768px) {
  .chatpdfArea {
    .send,
    .svg {
      width: 30px;
      height: 30px;
    }
  }
}
@media (max-height: 600px) {
  .chatpdfTips {
    width: 100%;
    padding: 0 10px;
  }
}
@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-3px);
  }
  50% {
    transform: translateX(3px);
  }
  75% {
    transform: translateX(-3px);
  }
}
