.loginBox {
  padding: 36px;
  display: flex;
  flex-direction: column;
  background: var(--login-right-bg);
  color: var(--primary-color);
  position: fixed;
  bottom: 10px;
  left: 10px;
  border-radius: 33px;
  z-index: 999;
  .describe {
    display: flex;
  }
  .left {
    display: flex;
    flex-direction: column;
    .title {
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 16px;
    }
    .function {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      .umlIcon {
        width: 60px;
        height: 60px;
        background: url("/login/UMLIcon.png") 100% 100% no-repeat;
        background-size: cover;
        margin-right: 14px;
      }
      .upLoadIcon {
        width: 60px;
        height: 60px;
        background: url("/login/upLoadIcon.png") 100% 100% no-repeat;
        background-size: cover;
        margin-right: 14px;
      }
      .loginIcon {
        width: 60px;
        height: 60px;
        background: url("/login/LoginIcon.png") 100% 100% no-repeat;
        background-size: cover;
        margin-right: 14px;
      }
    }
  }
  .right {
    background: var(--chat-bg);
    padding: 10px;
    margin-left: 32px;
    margin-bottom: 16px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    .wechat_qr_img {
      width: 190px;
      height: 190px;
    }
    .loginDescribe {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      .wxIcon {
        width: 26px;
        height: 22px;
        background: url("../../public/login//wxIcon.png") 100% 100% no-repeat;
        background-size: cover;
        margin-right: 5px;
      }
      .title {
        font-weight: 600;
        font-size: 18px;
      }
    }
    .QRBox {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      aspect-ratio: 1;
      background-color: #fff;
      border-radius: 18px;
      overflow: hidden;
      -webkit-border-radius: 18px;
      -moz-border-radius: 18px;
      -ms-border-radius: 18px;
      -o-border-radius: 18px;
    }
    .QRMask {
      position: relative;
      display: inline-block;
      /*让伪元素的大小与图片大小一致*/
      aspect-ratio: 1;
      cursor: pointer;
      border-radius: 18px;
      overflow: hidden;
      -webkit-border-radius: 18px;
      -moz-border-radius: 18px;
      -ms-border-radius: 18px;
      -o-border-radius: 18px;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("../..//public/login/QRmask.png") no-repeat;
        background-size: cover;
        pointer-events: none; /* 允许点击穿透蒙层 */
        z-index: 99;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #8e8e8e;
        opacity: 0.5;
        background-size: cover;
        pointer-events: none; /* 允许点击穿透蒙层 */
      }
    }
  }
  .loginBtn {
    margin-top: 10px;
  }
}
