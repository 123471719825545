.wraperOverlay {
    position: fixed;
    top: 0;
    left: 382px;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
  }
.wraper {
  background: var(--modal-background-color);
  color: var(--primary-color);
//   position: fixed;
//   top: 5%;
//   left: 20%;
  width: 80%;
  height: 75%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    height: 62px;
    .title {
        max-width: 60%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
  }

  .closeButton {
    background: none;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    width: 30px;
    height: 30px;
  }
  .modalBody {
    flex: 1;
    margin: 20px;
    overflow-y: auto;
  }
}
