.codeTree {
  height: 100%;
  padding: 0 0 0 22px;
  .treeNode {
    cursor: pointer;
    margin: 1px 0;
    &:hover {
      background-color: var(--tree-select-color);
      border-radius: 10px;
    }
    display: flex;
    align-items: center;
    padding: 10px 20px;
    .switcherIcon {
      margin-right: 5px;
      display: flex;
      align-items: center;
    }
    .icon {
      margin-right: 8px;
    }
    .title {
      flex: 1;
      font-weight: 400;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .treeNode_select {
    background-color: var(--tree-select-color);
    border-radius: 10px;
  }
  .children_open {
    display: block;
  }
  .children_close {
    display: none;
  }
  .loading {
    padding-left: 20px;
    display: flex;
    align-items: center;
    .loadingText {
      font-size: 14px;
    }
  }
}
