.message_show{
    height: 15px;
    background: #FFFFFF;
    border-radius: 2px;
    position: fixed;
    top: 12%;
    left: 50%;
    opacity: 1;
    padding: 10px 14px;
  }
  .message{
    // height: 15px;
    background: #FFFFFF;
    border-radius: 2px;
    position: fixed;
    top: 10%;
    left: 50%;
    // opacity: 0;
    padding: 10px 14px;
    transform: translate(-50%, -50%);
    transition:0.4s;
    z-index: 3;
    -webkit-transition-timing-function:ease;
  }
  .message_icon_dg{
    width: 15px;
    height: 15px;
    background: url("../public/tips/dg.png");
    float: left;
  }
  .message_icon_bc{
    width: 15px;
    height: 15px;
    background: url("../public/tips/bc.png");
    float: left;
  }
  .message p{
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #001B35;
    float: left;
    margin-left: 5px;
    line-height: 15px;
  }